<template>
  <div class="TP-Search">
    <div class="stu-module-title">教学计划</div>
    <!-- <el-form :model="form" inline ref="tpForm" class="form-btn-group">
      <template v-if="$sysPerKey('eduTeachPlan:queryTeachPlanByStudent')">
        <el-form-item label="课程名称" prop="courseName">
          <el-input
            v-model="form.courseName"
            placeholder="请输入"
            size="medium"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item class="button-item">
          <el-button @click="reset('tpForm')">重置</el-button>
          <el-button type="primary" @click="query">查询</el-button>
        </el-form-item>
      </template>
    </el-form> -->
  </div>
</template>

<script>
export default {
  name: "tp-search",
  data() {
    return {
      form: {
        courseName: "",
      },
    };
  },
  computed: {},
  methods: {
    reset(formName) {
      this.$refs[formName].resetFields();
    },
    query() {
      const courseName = this.form.courseName;
      this.$emit("tpQuery", { courseName });
    },
  },
};
</script>

<style scoped lang="less">
.TP-Search {
  box-shadow: @shadow;
  background: #fff;
  margin-bottom: 20px;
  padding: 20px;
  .stu-module-title {
    // padding: 20px 10px 0;
  }
}
</style>
<template>
  <div class="Teaching-Plan">
    <TpSearch @tpQuery="tpQuery" />

    <TpTable
      :query="courseName"
      v-if="$sysPerKey('eduTeachPlan:queryTeachPlanByStudent')"
    />
    <no-root v-else></no-root>
  </div>
</template>

<script>
import TpSearch from "./module/TpSearch.vue";
import TpTable from "./module/TpTable.vue";
export default {
  components: { TpSearch, TpTable },
  data() {
    return {
      courseName: "",
    };
  },
  methods: {
    tpQuery(data) {
      this.courseName = data.courseName;
    },
  },
};
</script>

<style scoped lang="less">
.Teaching-Plan {
}
</style>
<template>
  <div class="TP-Table">
    <el-table
      :data="datalist"
      :height="height"
      v-loading="loading"
      :default-sort="{ prop: 'semester', order: 'null' }"
      ref="tableRef"
      @sort-change="sortChange"
      stripe
    >
      <el-table-column label="序号" :width="60" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in columns"
        :prop="item.prop"
        :key="index"
        :label="item.label"
        align="center"
        :min-width="item.width"
        :sortable="item.sortable"
      ></el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="tableData.length"
    >
    </el-pagination>
  </div>
</template>

<script>
import { QueryTeachPlanByStudent } from "@/libs/api/student";
export default {
  name: "tp-table",
  props: {
    query: String,
  },
  watch: {
    query(n) {
      this.current = 1;
      this.tableData = this.allTable;
      if (n) {
        this.tableData = this.allTable.filter(
          (f) => f.courseName.indexOf(n) > -1
        );
      }
      this.queryTable = JSON.parse(JSON.stringify(this.tableData));
    },
  },
  data() {
    return {
      height: window.innerHeight - 300,
      loading: false,
      allTable: [],
      tableData: [],
      queryTable: [], // 查询的列表
      columns: [
        { prop: "courseName", label: "课程名称", width: 200 },
        { prop: "courseType", label: "课程性质" },
        { prop: "degreeFlags", label: "是否学位课程" },
        { prop: "enableds", label: "是否已开课" },
        { prop: "semester", label: "学期", sortable: "custom" },
      ],
      pageSize: 10,
      current: 1,
      total: 0,
    };
  },
  computed: {
    datalist() {
      return this.tableData.slice(
        (this.current - 1) * this.pageSize,
        this.current * this.pageSize
      );
    },
    queryRoot() {
      return this.$sysPerKey("eduTeachPlan:queryTeachPlanByStudent");
    },
  },
  watch: {
    queryRoot(n) {
      if (n) {
        this.init();
      }
    },
  },
  mounted() {
    this.queryRoot && this.init();
  },
  methods: {
    init() {
      this.loading = true;
      QueryTeachPlanByStudent()
        .then((res) => {
          if (res.data) {
            this.tableData = res.data;
            this.allTable = res.data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleCurrentChange(page) {
      if (page) this.current = page;
    },
    sortChange({ column, prop, order }) {
      if (!order) {
        this.tableData = JSON.parse(JSON.stringify(this.allTable));
        return;
      }
      let arr = JSON.parse(JSON.stringify(this.tableData));
      this.tableData = arr.sort((a, b) => {
        let v1 = a[prop];
        let v2 = b[prop];
        // ascending
        return order === "descending" ? v1 - v2 : v2 - v1;
      });
    },
  },
};
</script>

<style scoped lang="less">
.TP-Table {
  padding: 10px;
  box-shadow: @shadow;
  background: #fff;
}
</style>